declare global{
  interface Window{
    optimizely: any;
    gtag: any;
  }
}

export const isBrowser = typeof window !== 'undefined';

export const isMobile = isBrowser && (
  /* Mobile */ /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
  /* iPad */ navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)
);

if (isBrowser) window.optimizely = window.optimizely || [];

export const sendEvent = (eventName, tags = {}) => {
  if (!isBrowser) return;
  window.optimizely.push({
    type: 'event',
    eventName,
    tags
  });
  window.gtag('event', eventName, {
    // 'event_label': 'none',
    // 'event_category': 'custom',
    'non_interaction': true
  });
};
