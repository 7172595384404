import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';

type UtmCtaProps = {
  href: string,
  className?: string;
  children: React.ReactNode;
  target?: string;
  rel?: string
};
export default function UtmCta({ href: rawHref, className, children, target, rel }: UtmCtaProps) {
  const location = useLocation();
  const [ href, setHref ] = useState(rawHref);

  //We need to use useEffect since the window location cannot be determined at server render time so it needs to be dynamic like this.
  useEffect(()=>{
    if (location.search === ''){
      setHref(rawHref);
    }else{
      const url = new URL(href);
      url.searchParams.set('utm_initial_referrer', location.search);
      setHref(url.href);
    }
  }, [ location ]);

  return <a
    className={className}
    href={href}
    target={target}
    rel={rel}>
    {children}
  </a>;
}
