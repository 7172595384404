import React from 'react';
import styled from 'styled-components';
import { media } from '../../includes/breakpoints';
import Logo from '../../images/logo.inline.svg';
import PlansScrollAnchor from '../home/Hero/PlansScrollAnchor';
import UtmCta from '../utils/UtmCta';

const NavigationStyle = styled.nav`
  .container{
    padding-top: 8px;
    padding-bottom: 8px;

    ${media.desktop`
      padding-top: 16px;
      padding-bottom: 16px;
    `}

    display: flex;
    align-items: center;
  }

  .logo-wrapper{
    padding-right: 15px;
    flex-grow: 1;
    display: flex;
    align-items: center;

    .logo{
      display: block;
      min-width: 100px;
    }
  }

  .btn{
    font-size: 16px;
    line-height: 1;
    padding: 14px 24px;
  }

  .cta-login{
    display: none;

    ${media.desktop`
      display: inline;
    `}
  }
`;

export default function Header(){
  return <NavigationStyle id="navigation" className="navbar">
    <div className="container">
      <div className="logo-wrapper">
        <a href="https://restream.io/">
          <Logo />
        </a>
      </div>
      <nav id="main-menu" className={`navbar-menu`}>
        <UtmCta className="cta-login btn" href="https://restream.io/login">Log in</UtmCta>
        <PlansScrollAnchor className="cta-join btn btn--purple">Join today</PlansScrollAnchor>
      </nav>
    </div>
  </NavigationStyle>;
}
