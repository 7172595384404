import { normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { media } from './breakpoints';

// Graphik Fonts
import GraphikRegularWOFF from '../fonts/Graphik-Regular-Web.woff';
import GraphikRegularWOFFT from '../fonts/Graphik-Regular-Web.woff2';
import GraphikRegularItalicWOFF from '../fonts/Graphik-RegularItalic-Cy-Web.woff';
import GraphikRegularItalicWOFFT from '../fonts/Graphik-RegularItalic-Cy-Web.woff2';
import GraphikMediumWOFF from '../fonts/Graphik-Medium-Web.woff';
import GraphikMediumWOFFT from '../fonts/Graphik-Medium-Web.woff2';
import GraphikSemiBoldWOFF from '../fonts/Graphik-Semibold-Cy-Web.woff';
import GraphikSemiBoldWOFFT from '../fonts/Graphik-Semibold-Cy-Web.woff2';
import GraphikBoldWOFF from '../fonts/Graphik-Bold-Web.woff';
import GraphikBoldWOFFT from '../fonts/Graphik-Bold-Web.woff2';

import JollyLodgerWOFF from '../fonts/JollyLodger-Regular.woff2';
import JollyLodgerWOFFT from '../fonts/JollyLodger-Regular.woff2';

export const colors = {
  black: '#000000', //Squid Ink
  offBlack: '#111927', //Off Black
  white: '#FFFFFF', //White
  silver: '#F7F7F7',
  electricBlue: '#0000FF', //Electric Blue
  oceanBlue: '#286FFF', //Ocean Blue
  darkPurple: '#6E44FF', //Pale Indigo
  purple: '#9944FF', //Purple
  hotPink: '#FF44EE', //Hot Pink
  red: '#FF4A5B', //Red
  darkOrange: '#FF6633', //Cephalorange
  orange: '#FFBB2C', //Mandarin
  yellow: '#FFDD00', //Sunshine Yellow
  lime: '#BBFF00', //Lime
  seaGreen: '#14EDAC', //Sea Green
} as const;

export const desktopPadding = 40;

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  @font-face {
    font-family: "Graphik";
    src: url('${GraphikRegularWOFFT}') format("woff2"), url('${GraphikRegularWOFF}') format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Graphik";
    src: url('${GraphikRegularItalicWOFFT}') format("woff2"), url('${GraphikRegularItalicWOFF}') format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  /* @font-face {
    font-family: "Graphik-Regular";
    src: url('fonts/Graphik-Regular.woff2') format("woff2"), url('fonts/Graphik-Regular.woff') format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  } */
  @font-face {
    font-family: "Graphik";
    src: url('${GraphikMediumWOFFT}') format("woff2"), url('${GraphikMediumWOFF}') format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Graphik";
    src: url('${GraphikSemiBoldWOFFT}') format("woff2"), url('${GraphikSemiBoldWOFF}') format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Graphik";
    src: url('${GraphikBoldWOFFT}') format("woff2"), url('${GraphikBoldWOFF}') format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  /* @font-face {
    font-family: "Graphik-Semibold";
    src: url('fonts/Graphik-Semibold.woff2') format("woff2"), url('fonts/Graphik-Semibold.woff') format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  } */
  @font-face {
    font-family: "Roc Grotesk";
    src: url("https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
  }
  @font-face {
    font-family: "Roc Grotesk";
    src: url("https://use.typekit.net/af/5eb19c/00000000000000007735b7d0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/5eb19c/00000000000000007735b7d0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/5eb19c/00000000000000007735b7d0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
  }
  @font-face {
    font-family: "Roc Grotesk";
    src: url("https://use.typekit.net/af/c4fccd/00000000000000007735b7c0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/c4fccd/00000000000000007735b7c0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/c4fccd/00000000000000007735b7c0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
  }
  @font-face {
    font-family: "Roc Grotesk";
    src: url("https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
  }
  /* @font-face {
    font-family: JollyLodger-Regular;
    src: url('${JollyLodgerWOFFT}') format("woff2"), url('${JollyLodgerWOFF}') format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  } */

  #gatsby-focus-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > #content{
      flex-grow: 1;
    }
  }

  body {
    font-family: Graphik, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: ${colors.black};
    background: white;
    /* min-width: 375px; */
  }

  a{
    color: black;
    text-decoration: none;

    &:hover{
      color: ${colors.darkPurple};
    }

    svg{
      vertical-align: middle;
    }
  }

  strong{
    font-weight: 600;
  }

  button{
    cursor: pointer;
    color: inherit; //This is important for iOS
    padding: 6px; //This differs between browsers
  }

  //This is off screen until you use tab to focus the element
  .skip-to-content {
    position: absolute;
    right: 100%;

    &:focus{
      z-index: 10000;
      right: 50%;
      padding: 4px 12px;
      background: white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0px 2px 6px rgba(0,0,0,.5);
      transform: translateX(50%);
    }
  }

  .container {
    max-width: ${1128 + desktopPadding*2}px;
    padding: 52px 16px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    ${media.mobile`
      padding: 60px ${desktopPadding}px;
    `}
  }

  h1,.h1 {
    font-family: "Roc Grotesk", Helvetica, Arial, sans-serif;
    font-size: 28px;
    line-height: 35px;
    font-weight: 700;
    text-align: center;

    margin: 0 0 24px;

    ${media.mobile`
      font-size: 48px;
      line-height: 60px;
    `}
  }

  h2,.h2 {
    font-family: "Roc Grotesk", Helvetica, Arial, sans-serif;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    margin: 0 0 28px;

    font-size: 36px;
    ${media.tablet`
      font-size: 42px;
    `}
    ${media.desktop`
      font-size: 48px;
    `}
  }

  h3,.h3 {
    font-family: 'Roc Grotesk';
    font-weight: 700;
    font-size: 40px;
    line-height: 1;

    margin: 0 0 24px;

    ${media.desktop`
      font-size: 44px;
      line-height: 1;
    `}
  }

  h4,.h4{
    font-family: 'Roc Grotesk';
    font-weight: 400;
    font-size: 26px;
    line-height: 1;
    margin: 0 0 24px;

    ${media.desktop`
      font-size: 40px;
      line-height: 1;
    `}
  }

  h5,.h5{
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 4px 0;
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn{
    display: inline-block;
    border-radius: 2em;
    font-size: 1em;
    line-height: 1;
    font-weight: 600;
    /* padding: 14px 24px; */
    padding: 0.875em 1.5em;
    text-decoration: none;
    transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px 0px; */
    text-align: center;

    &--purple{
      border: 2px solid ${colors.darkPurple};
      background: ${colors.darkPurple};
      color: ${colors.white};

      &:hover {
        background: none;
        color: ${colors.darkPurple};
      }
    }

    &--purple-hollow{
      border: 2px solid ${colors.darkPurple};
      background: none;
      color: ${colors.black};

      &:hover {
        color: ${colors.darkPurple};
      }
    }

    &--blue{
      border: 2px solid ${colors.oceanBlue};
      background: ${colors.oceanBlue};
      color: ${colors.white};

      &:hover {
        background: none;
        color: ${colors.oceanBlue};
      }
    }

    &--blue-hollow{
      border: 2px solid ${colors.oceanBlue};
      background: none;
      color: ${colors.black};

      &:hover {
        color: ${colors.oceanBlue};
      }
    }

    &--black{
      border: 2px solid ${colors.black};
      background: ${colors.black};
      color: ${colors.white};

      &:hover {
        background: none;
        color: ${colors.black};
      }
    }

    &--yellow{
      border: 2px solid ${colors.yellow};
      background: ${colors.yellow};
      color: black;

      &:hover {
        background: none;
        color: ${colors.yellow};
      }
    }
  }

  svg{
    //iOS Safari tends to shrink SVG on us while other browsers don't
    flex-shrink: 0;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;
