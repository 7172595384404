import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { media } from '../../includes/breakpoints';
import { colors } from '../../includes/style';
import { useViewport } from '../context/ViewportProvider';
import Navigation from './Navigation';

const DESKTOP_NAV_TOP = 30;//px
const DESKTOP_NAV_STICKY_MARGIN = 20;//px
const DESKTOP_SCROLL_DEPTH = DESKTOP_NAV_TOP-DESKTOP_NAV_STICKY_MARGIN;//px

const HeaderStyle = styled.header`
  position: sticky;
  top: 0;
  z-index: 2000;

  display: flex;
  flex-direction: column;
  background: white;
  transition: background-color .25s, box-shadow .25s;
  &.sticky{
    box-shadow: 0 4px 10px -10px rgb(0 0 0 / 60%);
  }

  #navigation{
    margin-left: 8px;
    margin-right: 8px;
  }

  ${media.desktop`
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    background: none;

    #navigation{
      margin-top: ${DESKTOP_NAV_TOP}px;
      margin-left: 20px;
      margin-right: 20px;
    }

    #navigation .container{
      border-radius: 24px;
    }

    &.sticky{
      position: fixed;
      top: 0;
      padding: 0;
      box-shadow: none;

      #navigation{
        margin-top: ${DESKTOP_NAV_STICKY_MARGIN}px;
      }

      #navigation .container{
        background: ${colors.white};
        box-shadow: 0 4px 10px -10px rgb(0 0 0 / 60%);
      }
    }
  `}
`;

const Placeholder = styled.div`
  ${media.desktop`
    position: relative;
    top: ${DESKTOP_SCROLL_DEPTH}px;
  `}
`;

export default function Header(){
  const placeholderRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const { width, setHeaderHeight } = useViewport();

  useEffect(()=>{
    const elHeader = headerRef.current;
    const elPlaceholder = placeholderRef.current;
    if (!elHeader || !elPlaceholder) return;
    const observer = new IntersectionObserver(([e]) =>{
      elHeader.classList.toggle('sticky', e.intersectionRatio < 1);
    });
    observer.observe(elPlaceholder);
  }, [headerRef, placeholderRef]);

  useEffect(()=>{
    const elHeader = headerRef.current;
    if (!elHeader) return;
    setHeaderHeight(elHeader.offsetHeight);
  }, [ width ]);

  return <>
    <Placeholder className="placeholder" ref={placeholderRef}></Placeholder>
    <HeaderStyle id="header" ref={headerRef}>
      <Navigation />
    </HeaderStyle>
  </>;
}
