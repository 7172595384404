import React, { ReactNode } from 'react';
import { isBrowser } from '../../../includes/analytics';
import { breakpoints } from '../../../includes/breakpoints';
import { ScrollAnchor } from '../../utils/ScrollAnchor';

type PlansScrollAnchorProps = {
  children?: ReactNode,
  className?: string,
};
export default function PlansScrollAnchor({ className, children }:PlansScrollAnchorProps){
  const getAnchor = ()=>isBrowser && window.innerWidth < breakpoints.mobile ? 'plans-grid' : 'plans';
  return <ScrollAnchor className={className} anchor={getAnchor}>{children}</ScrollAnchor>;
}
