export const initIntercom = ()=>{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w = window as any;
  w.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'wvwee5xi',
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/wvwee5xi'
  (function () {
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/wvwee5xi';
        const x = d.getElementsByTagName('script')[0];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        x.parentNode!.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};
