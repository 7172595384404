import React, { useEffect } from 'react';
import Header from './layout/Header';
import { GlobalStyle } from '../includes/style';
import { initIntercom } from '../includes/intercom';
import styled from 'styled-components';
import OldBrowserButterBar from './modals/OldBrowserButterBar';

//This is necessary to get the OldBrowserButterBar to work with both desktop and mobile nav
const Wrapper = styled.div`
  position: relative;
`;

type LayoutProps = {
  children: React.ReactNode
};
export default function Layout({ children }:LayoutProps){
  useEffect(()=>{
    initIntercom();
  }, []);

  return <>
    <GlobalStyle />
    <a className="skip-to-content" href="#content">Skip to main content</a> {/* For A11y */}
    <OldBrowserButterBar />
    <Wrapper>
      <Header />
      <main id="content">{children}</main>
    </Wrapper>
  </>;
}
